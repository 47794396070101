import "./sections.css"

import React from "react"

import axios from "axios"
import moment from "moment"

import { API_BASE_URL } from "../../constants/config"
import { defaultDog } from "../../constants/defaults"

const Section1 = () => {

    return <section className="in-memoriam-sec1">
        <div className="content">
            <h3>These dogs have found their way to the Rainbow Bridge…</h3>
        </div>
    </section>
}
const Section2 = () => {

    const [data, setData] = React.useState(null);
    const [years, setYears] = React.useState([]);
    const [year, setYear] = React.useState(null);

    React.useEffect(() => {
        var m = true;
        if (m) {
            if (year) {
                var params = {
                    year: year
                }
            }
            axios.get(API_BASE_URL + "/website/dogs/in-memoriam", { params: params }).then(({ data }) => {
                setData(data.data.data);
                setYears(data.data.years)
            })
        }
        return () => {
            m = false;
        }
    }, [year])

    return <section className="in-memoriam-sec2">
        <div className="years-cont">
            <ul>
                {years && years.map((item, key) => {
                    return <li className={year === item.year ? 'active' : ''} key={key}><button onClick={() => { setYear(item.year) }} ><svg xmlns="http://www.w3.org/2000/svg" id="heart_icn" width="26.986" height="12.273" data-name="heart icn" viewBox="0 0 26.986 12.273">
                        <path id="Path_189" d="M6.048 166.423a3.4 3.4 0 0 0-1.548-.07 14.707 14.707 0 0 1-2.651.258A13.381 13.381 0 0 1 0 166.474a4.007 4.007 0 0 0 2.346 1.839 5.493 5.493 0 0 0 .633.164H3a6.859 6.859 0 0 0 1.164.174.352.352 0 0 1 0 .7 6.369 6.369 0 0 1-.849-.1A1.492 1.492 0 0 0 4.1 170.4a2.9 2.9 0 0 0 1.407-.141.352.352 0 0 1 .375.6 2.266 2.266 0 0 1-1.027.258 2.632 2.632 0 0 0 1.924.938 3.016 3.016 0 0 0 1.309-.338 7.506 7.506 0 0 1-2.013-3.251 3.969 3.969 0 0 1-.028-2.041z" className="cls-1" data-name="Path 189" transform="translate(0 -163.898)" />
                        <path id="Path_190" d="M328.532 166.61a14.707 14.707 0 0 1-2.651-.258 3.4 3.4 0 0 0-1.548.07 3.959 3.959 0 0 1 0 2.041 7.506 7.506 0 0 1-2.013 3.256 3.017 3.017 0 0 0 1.309.338 2.637 2.637 0 0 0 1.924-.938 2.262 2.262 0 0 1-1.027-.258.352.352 0 1 1 .375-.6 2.96 2.96 0 0 0 1.407.141 1.492 1.492 0 0 0 .779-1.145 6.354 6.354 0 0 1-.849.1.352.352 0 0 1 0-.7 6.849 6.849 0 0 0 1.164-.174h.023a5.417 5.417 0 0 0 .633-.164 4.025 4.025 0 0 0 2.346-1.839 13.369 13.369 0 0 1-1.872.131z" className="cls-1" data-name="Path 190" transform="translate(-303.417 -163.898)" />
                        <path id="Path_191" d="M125.366 126.923a3.65 3.65 0 0 0-2.815-1.483h-.127a3.189 3.189 0 0 0-2.895 2.144.352.352 0 0 1-.671 0 3.15 3.15 0 0 0-2.895-2.144h-.127a3.65 3.65 0 0 0-2.815 1.483 3.42 3.42 0 0 0-.615 2.909c.446 1.708 1.834 3 3.284 4.359.469.469.995.938 1.473 1.408.882.905 1.623 1.708 2 2.116.375-.408 1.117-1.21 2-2.116.469-.493.985-.962 1.473-1.408 1.468-1.365 2.857-2.651 3.284-4.363a3.42 3.42 0 0 0-.554-2.9zm-.788 2.613h-.028a.385.385 0 0 1-.38-.357 1.914 1.914 0 0 0-.633-1.44 2.318 2.318 0 0 0-1.609-.38.385.385 0 0 1-.122-.755 3.064 3.064 0 0 1 2.2.535 2.637 2.637 0 0 1 .938 1.989.385.385 0 0 1-.365.4z" className="cls-1" data-name="Path 191" transform="translate(-105.722 -125.44)" />
                    </svg>
                        {item.year}</button></li>
                })
                }
            </ul>
        </div>
        <div className="content">
            {data && data.map((item, key) => {
                return <div key={key} className="item">
                    <div className="thumb">
                        <img src={item.image ? API_BASE_URL.replace("/v1", "/") + item.image : defaultDog} alt={item.title} />
                    </div>
                    <div className="text">
                        <h3>{item.title} <span className="dod">{moment(item.date_of_death).format("MMMM DD, YYYY")}</span></h3>
                        <div className="text-content site-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                </div>
            })}
        </div>
    </section>
}
const Sections = {
    Section1,
    Section2
}

export default Sections