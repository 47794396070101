import React from "react"
import Sections from "../components/in-memoriam/sections"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/in-memoriam-banner-bg.jpg"
const InMemoriamPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Memoriam" />
    <InnerBanner theme="white" image={banner} title="In Memoriam" subtitle="Dogs" />
  
  <Sections.Section1/>
  <Sections.Section2/>
  </Layout>
}

export default InMemoriamPage